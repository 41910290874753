import { frFR } from "@mui/material/locale";
import saMessages from "../locales/fr_FR.json";

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: frFR,
  locale: "fr-FR",
};
export default saLang;
